import axios from 'axios'
import { useAuth } from 'Components/Auth'
import { INITIAL_LOCAL_STORAGE_DATA, usePersistedLoggedFromId } from 'hooks/usePersistedData'
import { useCallback } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'

import QUERY from './constants'

export const useLoginMutation = (options) => {
  const { setAuth } = useAuth()
  const queryClient = useQueryClient()

  const onSuccess = useCallback(
    (data) => {
      queryClient.clear()
      queryClient.invalidateQueries(QUERY.USER)

      const { user, ...otherProps } = data.data

      setAuth({
        ...user,
        ...otherProps,
      })
    },
    [setAuth, queryClient],
  )

  return useMutation((payload) => axios.post(QUERY.LOGIN, payload), {
    mutationKey: QUERY.LOGIN,
    onSuccess: onSuccess,
    ...options,
  })
}

export const useLoginAsUser = () => {
  const { id, setAuth, axios } = useAuth()
  const queryClient = useQueryClient()
  const [, setLoggedFromId] = usePersistedLoggedFromId()

  const onSuccess = useCallback(
    (data) => {
      queryClient.invalidateQueries(QUERY.USER)

      const { user, ...otherProps } = data.data

      setAuth({
        ...user,
        ...otherProps,
      })

      setLoggedFromId(id)
    },
    [id, setAuth, queryClient, setLoggedFromId],
  )

  return useMutation((adminId) => axios.get(`${window.location.origin}${QUERY.SUPERADMIN}/${adminId}`), {
    mutationKey: QUERY.LOGIN,
    onSuccess: onSuccess,
  })
}

export const useLeaveUser = () => {
  const { setAuth, axios } = useAuth()
  const queryClient = useQueryClient()
  const [, setLoggedFromId] = usePersistedLoggedFromId()

  const onSuccess = useCallback(
    (data) => {
      queryClient.invalidateQueries(QUERY.USER)

      const { user, ...otherProps } = data.data

      setAuth({
        ...user,
        ...otherProps,
      })
      setLoggedFromId(INITIAL_LOCAL_STORAGE_DATA)
    },
    [setAuth, queryClient, setLoggedFromId],
  )

  return useMutation(() => axios.delete(`${QUERY.SUPERADMIN}`), {
    mutationKey: QUERY.LOGIN,
    onSuccess: onSuccess,
  })
}

export const useResetPasswordMutation = () => {
  const { axios } = useAuth()

  return useMutation((payload) => axios.post(QUERY.RESET_PASSWORD, payload))
}

export const useCheckRegister = () => {
  const { axios } = useAuth()

  return useMutation((payload) => axios.post(`${QUERY.REGISTER}/check`, payload))
}

export const useSetNewPasswordMutation = () =>
  useMutation((payload) => axios.post(QUERY.NEW_PASSWORD, payload), {
    mutationKey: QUERY.NEW_PASSWORD,
  })

export const useRegisterMutation = () =>
  useMutation((payload) => axios.post(QUERY.REGISTER, payload), {
    mutationKey: QUERY.REGISTER,
  })

export const useLogoutMutation = (options) => {
  const { axios } = useAuth()
  const queryClient = useQueryClient()
  const [loggedFromId, setLoggedFromId] = usePersistedLoggedFromId()

  return useMutation(() => axios.post(QUERY.LOGOUT), {
    mutationKey: QUERY.LOGOUT,
    retry: false,
    onSettled: () => {
      queryClient.clear()
      queryClient.resetQueries(QUERY.USER, { exact: true })
      !!loggedFromId && setLoggedFromId(INITIAL_LOCAL_STORAGE_DATA)
    },
    ...options,
  })
}

export const SOCIAL_ACCOUNTS_NAME_ID = {
  LIVE: 'live',
  GOOGLE: 'google',
  DISCORD: 'discord',
  TWITTER: 'twitter',
  TWITCH: 'twitch',
}

export const useConnectedAccountsQuery = (enabled = true) => {
  const { axios } = useAuth()

  return useQuery(
    `${QUERY.AUTH}${QUERY.EXTERNALS}`,
    async () => {
      const { data } = await axios.get(`${QUERY.AUTH}${QUERY.EXTERNALS}`)
      return data
    },
    {
      enabled: enabled,
    },
  )
}

export const useDeleteConnectedAccountMutation = () => {
  const { axios } = useAuth()
  const queryClient = useQueryClient()

  return useMutation(({ id }) => axios.delete(`${QUERY.AUTH}${QUERY.EXTERNALS}/${id}`), {
    onSettled: () => {
      queryClient.invalidateQueries(`${QUERY.AUTH}${QUERY.EXTERNALS}`)
    },
  })
}

export const useUpdateUserPasswordMutation = () => {
  const { id, axios } = useAuth()

  return useMutation([`${QUERY.USERS}/${id}/password`, 'patch'], (payload) =>
    axios.patch(`${QUERY.USERS}/${id}/password`, payload),
  )
}

export const useSocialLogin = () => {
  const { mutate, isLoading } = useMutation(
    (accountId) => (window.location.href = `${window.location.origin}${QUERY.LOGIN}/${accountId}`),
  )

  return {
    login: mutate,
    isLoading,
  }
}

export const useAddExternalAccountToProfileMutation = () => {
  const { axios } = useAuth()

  return useMutation(({ accountId, parameters }) =>
    axios.post(`${window.location.origin}${QUERY.LOGIN}/${accountId}${parameters}`),
  )
}

export const useAuthorizeViaSocialAccountMutation = () =>
  useMutation(({ accountId, parameters }) =>
    axios.post(`${window.location.origin}${QUERY.LOGIN}/${accountId}${parameters}`),
  )

export const useChangeUserEmailMutation = () => {
  const { axios } = useAuth()
  return useMutation((email) => axios.patch(QUERY.LOGIN, { email }))
}

export const useSetNewEmailMutation = () => {
  const { axios } = useAuth()
  return useMutation((code) => axios.post(QUERY.AUTH_EMAIL, code))
}
